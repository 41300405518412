// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bx_k4";
export var caseStudyBackground__bgColor = "bx_kV";
export var caseStudyBackground__lineColor = "bx_kW";
export var caseStudyCta__bgColor = "bx_l3";
export var caseStudyHead__imageWrapper = "bx_kT";
export var caseStudyProjectsSection = "bx_k5";
export var caseStudyQuote__bgLight = "bx_k2";
export var caseStudyQuote__bgRing = "bx_k1";
export var caseStudyTechSection = "bx_lb";
export var caseStudyVideo = "bx_k7";
export var caseStudyVideo__ring = "bx_k8";
export var caseStudy__bgDark = "bx_kS";
export var caseStudy__bgLight = "bx_kR";